import React from "react";
import styled from "styled-components";
import CustomSelect from "../../Elements/CustomSelect/CustomSelect";
import { options } from "../../Utils/constants";

const Explorer = () => {
  return (
    <Container>
      <HeroSection>
        <Wrapper>
          <LogoImage src="./Assets/logo.png" />
          <Heading>Explorer</Heading>
        </Wrapper>
        <SelectionWrapper>
          <CustomSelect
            disabled
            options={options}
            defaultValue={"Controllers"}
          />
          <CustomSelect
            disabled
            options={options}
            defaultValue={"Accessories"}
          />
          <CustomSelect
            disabled
            options={options}
            defaultValue={"In-Game Items"}
          />
        </SelectionWrapper>
        <SelectionWrapper>
          <CustomSelect disabled options={options} defaultValue={"Hands"} />
          <CustomSelect
            disabled
            options={options}
            defaultValue={"Backgrounds"}
          />
          <CustomSelect disabled options={options} defaultValue={"Rarity"} />
        </SelectionWrapper>
        <SelectionItem>Coming Soon</SelectionItem>
      </HeroSection>
    </Container>
  );
};

export default Explorer;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000000;
  padding: 1rem 9rem;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroSection = styled.div`
  width: 1440px;

  @media screen and (max-width: 1201px) {
    scale: 0.9;
  }
  @media screen and (max-width: 1080px) {
    scale: 0.8;
  }
  @media screen and (max-width: 1024px) {
    scale: 0.7;
  }
  @media screen and (max-width: 768px) {
    scale: 0.6;
  }
`;
const Heading = styled.h1`
  color: white;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
`;
const SelectionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px #4240ff solid;
  border-radius: 10px;
  position: relative;
  margin-top: 0.5rem;
  color: white;
  font-size: 26px;
  height: 704px;
`;

const LogoImage = styled.img`
  width: 6rem;
  margin-right: 1rem;
  @media screen and (max-width: 600px) {
    width: 75px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    margin-top: 4.5rem;
  }
`;
