import React, { useState } from "react";
import styled from "styled-components";
import CustomSelect from "../../Elements/CustomSelect/CustomSelect";
import { options, list } from "../../Utils/constants";
import Modal from "../../Elements/Modal/Modal";
import { useNavigate } from "react-router-dom";

const MyControllerz = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const isProd = true;
  const navigate = useNavigate();
  const handleClick = (item) => {
    setDetail(item);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  return (
    <Container>
      <HeroSection>
        <Wrapper>
          <LogoImage src="./Assets/logo.png" />
          <Heading>My Controllerz</Heading>
        </Wrapper>
        <SelectionWrapper>
          <CustomSelect options={options} defaultValue={"Controllers"} />
          <CustomSelect options={options} defaultValue={"Accessories"} />
          <CustomSelect options={options} defaultValue={"In-Game Items"} />
        </SelectionWrapper>
        <SelectionWrapper>
          <CustomSelect disabled options={options} defaultValue={"Hands"} />
          <CustomSelect options={options} defaultValue={"Backgrounds"} />
          <CustomSelect options={options} defaultValue={"Rarity"} />
        </SelectionWrapper>
        {isProd ? (
          <ComimgSoon>Coming Soon</ComimgSoon>
        ) : (
          <>
            <ContainerWrapper>
              <SelectionItem>
                {list.map((item, i) => (
                  <List key={i} onClick={() => handleClick(item)}>
                    <ListImage src={item?.img} />
                    <ListItems>
                      <Text>
                        Type:{item?.type}
                        <Span>
                          Accessory:
                          <ListImage src={"./Assets/controller.png"} />
                        </Span>
                      </Text>
                      <Text>
                        {" "}
                        Hand:
                        <ListImage src={"./Assets/dark.png"} />
                      </Text>
                    </ListItems>
                  </List>
                ))}
              </SelectionItem>
              <BottomButton onClick={() => navigate("/")}>Menu</BottomButton>
              <Overly> </Overly>
              <Modal isOpen={isModalOpen} onClose={closeModal} data={detail} />
            </ContainerWrapper>
          </>
        )}
      </HeroSection>
    </Container>
  );
};

export default MyControllerz;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000000;
  padding: 1rem 9rem;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroSection = styled.div`
  width: 1440px;

  @media screen and (max-width: 1201px) {
    scale: 0.9;
  }
  @media screen and (max-width: 1080px) {
    scale: 0.8;
  }
  @media screen and (max-width: 1024px) {
    scale: 0.7;
  }
  @media screen and (max-width: 768px) {
    scale: 0.6;
  }
`;
const ContainerWrapper = styled.div`
  position: relative;
`;
const Heading = styled.h1`
  color: white;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const Span = styled.span`
  padding-left: 3rem;
`;
const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
`;
const Overly = styled.div`
  height: 8rem;
  padding: 2rem;
  background-image: linear-gradient(#00000052, black);
  position: absolute;
  bottom: 8px;
  width: 98.5%;
  left: 9px;
`;
const SelectionItem = styled.div`
  border: 8px #4240ff solid;
  border-radius: 10px;
  margin-top: 0.4rem;
  height: auto;
  overflow: scroll;
  min-width: 787px;
  max-height: 704px;
  padding: 5px 0px;
`;
const List = styled.div`
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;
const ListItems = styled.div`
  width: 100%;
  padding-left: 1rem;
`;
const Text = styled.p`
  height: 30px;
  color: #fff;
  font-size: 16px;
`;
const ListImage = styled.img``;

const BottomButton = styled.div`
  writing-mode: vertical-rl; /* Text ko vertical mode me rakhne ke liye */
  color: #ffff;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 1;
`;

const LogoImage = styled.img`
  width: 6rem;
  margin-right: 1rem;
  @media screen and (max-width: 600px) {
    width: 75px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    margin-top: 4.5rem;
  }
`;

const ComimgSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px #4240ff solid;
  border-radius: 10px;
  position: relative;
  margin-top: 0.5rem;
  color: white;
  font-size: 26px;
  height: 704px;
`;
