import React from "react";
import styled from "styled-components";

const Modal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Heading>Controller Details</Heading>
        <ModalDiscription>
          <ImageWrapper>
            <Image src={data?.img} />
          </ImageWrapper>
          <ContentWrapper>
            <BgDark>
              <Text>
                <Span>Controller:</Span>
                {data?.controller} <ImageIcon src={"./Assets/controller.png"} />
              </Text>
              <Text $pt={"3px"}>
                <Span>Hand:</Span>
                {data?.hand}
                <ImageIcon src="./Assets/dark.png" />
              </Text>
              <Text>
                <Span>Accessory:</Span>
                {data?.accessory}
                <ImageIcon $width src="./Assets/popcorn.png" />
              </Text>
              <Text>
                <Span>Background:</Span>
                {data?.background}
                <ImageIcon src="./Assets/crt.png" />
              </Text>
              <Text>
                <Span>In-Game Item:</Span>
                {data?.gameItem}
                <ImageIcon src="./Assets/dish.png" />
              </Text>
              <Text $pt={"7px"}>Rarity:{data?.rarity}</Text>
            </BgDark>
            <BgDanger>
              <Text $color={"black"}>Sat:{data?.sat}</Text>
              <Text $color={"black"}>Block:{data?.block}</Text>
              <Text $color={"black"}>Size:{data?.size}</Text>
              <Text $color={"black"}>Inscription:{data?.inscription}</Text>
            </BgDanger>
          </ContentWrapper>
        </ModalDiscription>
        <BottomButton onClick={onClose}>Back</BottomButton>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default Modal;

const BottomButton = styled.div`
  writing-mode: vertical-rl; /* Text ko vertical mode me rakhne ke liye */
  color: #ffff;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  cursor: pointer;
  background-color: #00000078;
  padding: 15px;
`;
const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  z-index: 9999;
  min-width: 787px;
`;

// Styled component for modal content
const ModalContent = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: #4240ff;
  border-radius: 8px;
  max-width: 1174px;
  max-height: 705px;
`;
const Span = styled.span`
  /*  */
`;
const Heading = styled.h1`
  margin: 0;
  padding: 0;
  color: white;
  font-size: 26px;
  font-weight: 400;
  word-wrap: break-word;
`;
const ModalDiscription = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;

const ImageWrapper = styled.div`
  width: 55%;
  height: 100%;
  border: 8px solid black;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const ContentWrapper = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
const BgDark = styled.div`
  padding: 1rem 1.5rem;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
`;
const BgDanger = styled.div`
  height: 146px;
  justify-content: center;
  padding: 1rem 1.5rem;
  background-color: #e31e26;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 8px 7px 5px 6px rgba(0, 0, 0, 0.47);
  gap: 1rem;
`;
const Text = styled.p`
  margin: 0;
  padding: 0;
  color: white;
  color: ${({ $color }) => ($color ? $color : "white")};
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: ${({ $pt }) => $pt && $pt};
`;
const ImageIcon = styled.img`
  width: ${({ $width }) => $width && "15px"};
`;
