import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes/AppRoutes";
import "./App.css";
// import PasswordEntry from "./Pages/PasswordEntry/PasswordEntry";
const App = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const handlePasswordSuccess = () => {
  //   setIsAuthenticated(true);
  // };

  return (
    <>
      {/* {isAuthenticated ? ( */}
      <Router>
        <AppRoutes />
      </Router>
      {/* ) : (
        <PasswordEntry onPasswordSuccess={handlePasswordSuccess} />
      )} */}
    </>
  );
};

export default App;
