import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import { ReactComponent as DownIcon } from "./down.svg";
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
const CustomSelect = ({ defaultValue, disabled = false, ...rest }) => (
  <ModifiedSelect
    suffixIcon={<DownIcon />}
    defaultValue={defaultValue}
    disabled
    {...rest}
    style={{
      width: "373px",
      height: "51px",
    }}
    onChange={handleChange}
  />
);
export default CustomSelect;

const ModifiedSelect = styled(Select)`
  margin: 0.4rem 0rem !important;

  .ant-select-selector {
    background: transparent !important;
    color: #ffff !important;
    border: 7px solid #4240ff !important;
    padding-right: 16px !important;
    font-size: 16px !important;
    opacity: ${({ disabled }) => disabled && 0.4};
    border-radius: 8px !important;
  }
  /* .ant-select-selection-item {
    width: 90%;
  } */
  .ant-select-customize-input {
    background: transparent !important;
  }
  .ant-select-arrow {
    margin-right: 1rem;
  }
`;
