export const options = [
    {
        value: "1",
        label: "Controllers",
    },
    {
        value: "2",
        label: "Accessories",
    },
    {
        value: "3",
        label: "In-Game Items",
    },
    {
        value: "4",
        label: "Hands",
    },
    {
        value: "5",
        label: "Backgrounds",
    },
    {
        value: "6",
        label: "Rarity",
    },
];

export const list = [
    {
        img: "./Assets/mycontrollerz1.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"


    },
    {
        img: "./Assets/mycontrollerz2.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz3.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz1.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz2.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz2.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz3.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz1.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
    {
        img: "./Assets/mycontrollerz2.png",
        type: "Gamepade",
        accessory: "Popcorn",
        hand: "Dark",

        sat: "12345678",
        block: "123456789",
        size: "4 kb",
        inscription: "1232834",
        rarity: "1,234",
        gameItem: "UAV",
        background: "CRT",
        controller: "2Shock 2"
    },
];