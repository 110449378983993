import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const About = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <Wrapper>
          <LogoImage src="./Assets/logo.png" />
          <Heading>About Controllerz</Heading>
        </Wrapper>
      </Header>
      <HeroSection>
        <HeroHeader>
          <BattlesWrapper>
            <Battles>
              <SubHeading>Battles</SubHeading>
              <BlankSection></BlankSection>
            </Battles>
            <VerticalHeading>Nostalgicz Team</VerticalHeading>
            <Battles>
              <SubHeading>Nikola</SubHeading>
              <BlankSection></BlankSection>
            </Battles>
          </BattlesWrapper>
        </HeroHeader>
        <Discription>
          <Content>
            Inspired by the gaming nostalgia that continues to shape our
            lives,Controllerz are a pixel art abstraction of the hardware and
            gamez that fueled our imagination. It is a collection of video game
            history intended to preserve and educate.
          </Content>
          <Content>
            Nostalgicz does not make any promises about future utility of the
            items in our collections. The art is the endgame. Collect and trade
            at your own risk. Stay tuned to X for updates.
          </Content>
        </Discription>
        <BottomButton onClick={() => navigate("/")}>Menu</BottomButton>
      </HeroSection>
    </Container>
  );
};

export default About;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  padding: 1rem 3rem;
  overflow: scroll;
  @media screen and (max-width: 1024px) {
    scale: 0.9;
  }
  @media screen and (max-width: 910px) {
    scale: 0.6;
  }
  @media screen and (max-width: 600px) {
    max-height: 100vh;
    scale: 1;
    padding: 0rem;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 400;
  color: white;
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    margin-top: 4.5rem;
  }
`;
const HeroSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  margin-bottom: 3rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 0rem;
  }
`;

const HeroHeader = styled.div`
  height: 445px;
  background-color: red;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Battles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;
const SubHeading = styled.h2`
  color: white;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
`;
const BlankSection = styled.div`
  width: 295px;
  height: 295px;
  background-color: #ffff;
`;
const VerticalHeading = styled.h2`
  writing-mode: vertical-rl;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 2.5rem;
`;
const BattlesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
const Discription = styled.div`
  width: 85%;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;
const Content = styled.p`
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
`;
const BottomButton = styled.div`
  writing-mode: vertical-rl; /* Text ko vertical mode me rakhne ke liye */
  color: #ffff;
  position: absolute;
  bottom: 0;
  right: 0.5rem;
`;

const LogoImage = styled.img`
  width: 6rem;
  margin-right: 1rem;
  @media screen and (max-width: 600px) {
    width: 75px;
  }
`;
