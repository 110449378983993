/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Controllerz = () => {
  const navigate = useNavigate();
  const tabsRef = useRef(null);

  const [tabs, setTabs] = useState([
    {
      active: true,
      name: "About",
      img: "./Assets/about.png",
      path: "/about",
    },
    {
      active: false,
      name: "Controllerz",
      img: "./Assets/controllerz.png",
      path: "/my-controllerz",
    },
    {
      active: false,
      name: "Explorer",
      img: "./Assets/explorer.png",
      path: "/explorer",
    },
    {
      active: false,
      name: "Achievements",
      img: "./Assets/achieve.png",
      path: "/achievements",
    },
  ]);

  const goToActiveTab = (newActiveIndex) => {
    const path = tabs[newActiveIndex].path;
    navigate(path);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const currentActiveIndex = tabs.findIndex((tab) => tab.active);
      let newActiveIndex = currentActiveIndex;

      switch (event.key) {
        case "ArrowRight":
          newActiveIndex = (currentActiveIndex + 1) % tabs.length;
          break;
        case "ArrowLeft":
          newActiveIndex = (currentActiveIndex - 1 + tabs.length) % tabs.length;
          break;
        case "Enter":
          goToActiveTab(currentActiveIndex);
          return;
      }

      if (newActiveIndex !== currentActiveIndex) {
        const newTabs = tabs.map((tab, index) => ({
          ...tab,
          active: index === newActiveIndex,
        }));
        setTabs(newTabs);

        // Scroll to the newly activated tab
        const tabElement = tabsRef.current.children[newActiveIndex];
        if (tabElement) {
          tabElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "end",
          });
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [tabs]);

  return (
    <Container>
      <ContainerItem>
        <Wrapper>
          <LogoImage src="./Assets/logo.png" />
          <Heading>Controllerz</Heading>
        </Wrapper>
        <HerWrapper>
          <HeroSection>
            <Tabs ref={tabsRef}>
              {tabs?.map((item, index) => (
                <Tab key={index}>
                  <SubHeading active={item.active}>
                    <Image
                      style={{
                        visibility: item?.active ? "visible" : "hidden",
                      }}
                      src="./Assets/arrow.svg"
                    />
                    {item?.name}
                  </SubHeading>
                  <TabImage
                    flag={item?.active}
                    src={item?.img}
                    alt="tab_image"
                  />
                </Tab>
              ))}
            </Tabs>
            <ControllerImage src="./Assets/controls.png" />
          </HeroSection>
          <Description>Use the controller.</Description>
        </HerWrapper>
      </ContainerItem>
    </Container>
  );
};

export default Controllerz;

const Container = styled.div`
  height: 1024px;
  background-color: #000000;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: block;
    height: 932px;
  }
`;
const ContainerItem = styled.div`
  @media screen and (max-width: 1149px) {
    scale: 0.8;
  }
  @media screen and (max-width: 910px) {
    scale: 0.6;
  }
  @media screen and (max-width: 600px) {
    max-height: 100vh;
    scale: 1;
    padding: 0rem;
  }
`;

const Image = styled.img`
  margin-right: 1rem;
  @media screen and (max-width: 600px) {
    width: 3rem;
  }
`;

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 400;
  color: white;
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    margin-top: 4.5rem;
  }
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    height: 100%;
  }
`;

const SubHeading = styled.h2`
  color: white;
  font-size: 22px;
  font-weight: 500;
  word-wrap: "break-word";
  display: flex;
  align-items: center;
  text-align: center;
  height: 50px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

const ControllerImage = styled.img`
  width: 387px;
  height: 254px;
  margin-top: 2.5rem;

  @media screen and (max-width: 600px) {
    margin-top: 4.5rem;
  }
`;

const Description = styled.div`
  width: 500px;
  height: 125px;
  border: 10px solid #ffff;
  background-color: #e31f26;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  font-weight: 500;
  margin-top: 3rem;
  @media screen and (max-width: 600px) {
    width: 380px;
    height: 100px;
    font-size: 16px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const TabImage = styled.img`
  transform: translateX(1.5rem);
  margin-top: 2rem;
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const HerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: 2rem;

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-bottom: 0rem;
  }
`;
const LogoImage = styled.img`
  width: 6rem;
  margin-right: 1rem;
  @media screen and (max-width: 600px) {
    width: 75px;
  }
`;
