import React from "react";
import { Route, Routes } from "react-router-dom";
import Controllerz from "../Pages/Controllerz/Controllerz";
import About from "../Pages/About/About";
import Explorer from "../Pages/Explorer/Explorer";
import MyControllerz from "../Pages/MyControllerz/MyControllerz";
import Achievements from "../Pages/Achievements/Achievements";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Controllerz />} />
      <Route path="/about" element={<About />} />
      <Route path="/explorer" element={<Explorer />} />
      <Route path="/my-controllerz" element={<MyControllerz />} />
      <Route path="/achievements" element={<Achievements />} />
    </Routes>
  );
};

export default AppRoutes;
